import Choices from '../webpack/node_modules/choices.js'

export default function SelectChoices() {
  let localisation = document.getElementById('select-localisation')

  if(localisation !== null){
    let count_localisation_number = localisation.childElementCount
    let count_localisation_tag = document.getElementById('count-localisation-tag')
    
    let localisations_array = []
    
    localisation.addEventListener('change', e => {
      // let dropdown = document.querySelectorAll('.choices__item--selectable')
      let dropbox = document.querySelector(".choices__list[role='listbox']")
      let tags_container = document.querySelector('.choices__list--multiple')
      let children = Array.from(e.target.children)
      let template_select=''
      children.forEach(child =>{
        template_select += `<option value=${child.value} selected="" data-custom-properties="[object" object]="">${child.label}</option>`
      })
      sessionStorage.setItem("options-selected-count", count_localisation_number)
      sessionStorage.setItem("options-tags", tags_container.innerHTML)
      sessionStorage.setItem("options-selectables", dropbox.innerHTML)
      sessionStorage.setItem("options-selected", template_select)
      count_localisation_number = localisation.childElementCount
      count_localisation_tag.innerHTML = count_localisation_number
  
      if (count_localisation_number === 0) {
        count_localisation_tag.classList.add('close-light-box')
      } else {
        count_localisation_tag.classList.remove('close-light-box')
      }
    })
    window.addEventListener('load', e =>{
      let dropbox = document.querySelector(".choices__list[role='listbox']")
      let tags_container = document.querySelector('.choices__list--multiple')
  
      let options_selected = sessionStorage.getItem("options-selected")
      let options_tags = sessionStorage.getItem("options-tags")
      let options_selectables = sessionStorage.getItem("options-selectables")
  
      localisation.innerHTML=options_selected
      tags_container.innerHTML=options_tags
      dropbox.innerHTML=options_selectables
      count_localisation_number = localisation.childElementCount
      count_localisation_tag.innerHTML = count_localisation_number
  
      if (count_localisation_number === 0) {
        count_localisation_tag.classList.add('close-light-box')
      } else {
        count_localisation_tag.classList.remove('close-light-box')
      }
    })
  
    for (let loc in annonce_form_data) {
      let element = annonce_form_data[loc]
      localisations_array.push({
        value: 'term_id_' + element.id,
        label: element.definition === 'ville' ? 'ville - ' + element.name : element.name,
        selected: false,
        disabled: false,
        customProperties: {
          region: element.parent,
        },
      })
    }
  
    if (localisation) {
      var Element = new Choices(localisation, {
        silent: false,
        items: [],
        choices: localisations_array,
        renderChoiceLimit: -1,
        maxItemCount: -1,
        addItems: true,
        addItemFilterFn: null,
        removeItems: true,
        removeItemButton: true,
        editItems: false,
        duplicateItemsAllowed: true,
        delimiter: ',',
        paste: true,
        searchEnabled: true,
        searchChoices: true,
        searchFloor: 1,
        searchResultLimit: 4,
        searchFields: ['label', 'value'],
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: true,
        shouldSortItems: false,
        sortFn: () => {},
        placeholder: true,
        placeholderValue: 'Tapez votre recherche ici- département(s), région(s)',
        searchPlaceholderValue: null,
        prependValue: null,
        appendValue: null,
        renderSelectedChoices: 'auto',
        loadingText: 'Chargement...',
        noResultsText: 'aucun résultat',
        noChoicesText: 'plus de choix disponible',
        itemSelectText: 'sélectionnez',
        addItemText: value => {
          return `Press Enter to add <b>"${value}"</b>`
        },
        maxItemText: maxItemCount => {
          return `Only ${maxItemCount} values can be added`
        },
        itemComparer: (choice, item) => {
          return choice === item
        },
        classNames: {
          containerOuter: 'choices',
          containerInner: 'choices__inner',
          input: 'choices__input',
          inputCloned: 'choices__input--cloned',
          list: 'choices__list',
          listItems: 'choices__list--multiple',
          listSingle: 'choices__list--single',
          listDropdown: 'choices__list--dropdown',
          item: 'choices__item',
          itemSelectable: 'choices__item--selectable',
          itemDisabled: 'choices__item--disabled',
          itemChoice: 'choices__item--choice',
          placeholder: 'choices__placeholder',
          group: 'choices__group',
          groupHeading: 'choices__heading',
          button: 'choices__button',
          activeState: 'is-active',
          focusState: 'is-focused',
          openState: 'is-open',
          disabledState: 'is-disabled',
          highlightedState: 'is-highlighted',
          hiddenState: 'is-hidden',
          flippedState: 'is-flipped',
          loadingState: 'is-loading',
          noResults: 'has-no-results',
          noChoices: 'has-no-choices'
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://github.com/krisk/Fuse#options
        fuseOptions: {
          include: 'score'
        },
        callbackOnInit: null,
        callbackOnCreateTemplates:  function(strToEl) {
          var classNames = this.config.classNames;
          return {
            choice: function(classNames, data) {
              // console.log(data)
                return strToEl('\
                    <div\
                      class="'+ String(classNames.item) + ' ' + String(classNames.itemChoice) + ' ' + String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable) + '"\
                      data-select-text="'+ "SELECTIONNER" + '"\
                      data-choice \
                      '+ String(data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable') + '\
                      data-id="'+ String(data.id) + '"\
                      data-value="'+ String(data.value) + '"\
                      '+ String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"') + '\
                      '+ String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"') + '\
                      >\
                      '+ String(data.customProperties.region ? '<span style="margin-right:10px;">departement - </span>' : '<span style="margin-right:10px;">region - </span>' ) + String(data.label) + '\
                    </div>\
                  ');
            }
          }
        }
      })
    }
  }
}
