let postuler_btn = document.querySelectorAll('.annonce_postuler-link')
let zone_form_offre_emploi = document.querySelector('#zoneform-offre-emploi_container')
let zone_form_offre_emploi_container = document.querySelector('.zoneform-offre-emploi_container')
let annonce_close_btn = document.querySelector('#close-btn')
let aside = document.querySelector('aside')

export default function Annonce() {
    if (zone_form_offre_emploi_container !== null) {
        postuler_btn.forEach(function (item) {
            item.addEventListener('click', function (e) {
                zone_form_offre_emploi_container.style.zIndex = '10'
                zone_form_offre_emploi_container.style.opacity = '1'
            })
            if(aside !== null ){
                item.addEventListener('click', function (e) {
                    aside.style.minHeight= '840px';
                })
            }
        })

        if (annonce_close_btn !== null) {
            annonce_close_btn.addEventListener('click', function (e) {
                zone_form_offre_emploi_container.style.opacity = '0'
                zone_form_offre_emploi_container.style.zIndex = '-10'
                aside.style.minHeight= '0';
            })
        }

        if(window.location.hash) {
            zone_form_offre_emploi_container.style.zIndex = '10'
            zone_form_offre_emploi_container.style.opacity = '1'
        }

        // if (postuler_btn !== null && window.innerWidth <= 1000 && aside !== null) {
        //   let lastScrollTop = 0
        //   window.addEventListener(
        //     'scroll',
        //     function() {
        //       let st = window.pageYOffset || document.documentElement.scrollTop
        //       if (
        //         (st > lastScrollTop ||
        //           aside.getBoundingClientRect().top + aside.offsetHeight <= aside.offsetHeight * 2) &&
        //         window.innerWidth <= 1000
        //       ) {
        //         // downscroll code
        //         postuler_btn.classList.add('close-light-box')
        //       } else {
        //         // upscroll code
        //         postuler_btn.classList.remove('close-light-box')
        //       }
        //       lastScrollTop = st <= 0 ? 0 : st
        //     },
        //     false
        //   )
        // }
    }
}
