export default function Share(){
  const facebook_share = document.getElementById('facebook-share')

  // ****** Facebook **************************//

  if(facebook_share !== null){
    facebook_share.addEventListener('click',()=>{
      FB.ui({
        method: 'share',
        href: window.location.href
      }, function(response) {});
    })
  }
  
  // ****** Twitter **************************//

  // var twitterBtn = document.querySelector('.twitter-share');

  // if(twitterBtn !== null){
  //   var getWindowOptions = function() {
  //     var width = 500;
  //     var height = 350;
  //     var left = (window.innerWidth / 2) - (width / 2);
  //     var top = (window.innerHeight / 2) - (height / 2);
    
  //     return [
  //       'resizable,scrollbars,status',
  //       'height=' + height,
  //       'width=' + width,
  //       'left=' + left,
  //       'top=' + top,
  //     ].join();
  //   };
    
    // var text = encodeURIComponent('Hey everyone, come & see how good I look!');
    // var shareUrl = 'https://twitter.com/intent/tweet?url=' + location.href + '&text=' + text;
    // var shareUrl = 'https://twitter.com/intent/tweet?url=' + location.href;
    // twitterBtn.href = shareUrl; // 1
    
    // twitterBtn.addEventListener('click', function(e) {
    //   e.preventDefault();
    //   var win = window.open(shareUrl, 'ShareOnTwitter', getWindowOptions());
    //   win.opener = null; 
    // });
  // }
}


