export default function LightBox() {
  let checkbox_Region = document.querySelectorAll('.input-region'),
    checkbox_dpt = document.querySelectorAll('.input-departement'),
    close_btn = document.getElementById('close-btn'),
    form_container = document.getElementById('form-container'),
    form_overlay = document.getElementById('form-overlay'),
    localisation_submit_btn = document.querySelector('#localisation-submit-btn'),
    departements_options = document.getElementById('departements-options'),
    regions_options = document.getElementById('regions-options'),
    regions_btn = document.getElementById('regions-btn'),
    departements_btn = document.getElementById('departements-btn'),
    localisation_select = document.getElementById('localisation-select')

  if (form_container !== null) {
    //**  *****************  Open Light-box *****************************  */
    localisation_select.addEventListener('click', () => {
      localisation_form_overlay.classList.toggle('close-light-box')
      form_container.classList.toggle('close-light-box')
    })

    //**  *****************  Close Light-box ****************************  */
    close_btn.addEventListener('click', () => {
      form_container.classList.add('close-light-box')
      localisation_form_overlay.classList.add('close-light-box')
    })

    localisation_form_overlay.addEventListener('click', () => {
      form_container.classList.add('close-light-box')
      localisation_form_overlay.classList.add('close-light-box')
    })

    //**  ***************** Dropdown tabs ******************************  */
    regions_btn.addEventListener('click', () => {
      if (!departements_options.classList.contains('show-options')) {
        departements_options.classList.toggle('show-options')
      }
      if (
        !departements_options.classList.contains('show-options') &&
        regions_options.classList.contains('show-options')
      ) {
        departements_options.classList.remove('show-options')
      }

      regions_options.classList.toggle('show-options')
      regions_btn.classList.toggle('before-btn-regions')
      regions_btn.classList.toggle('after-btn-regions')
    })

    departements_btn.addEventListener('click', () => {
      departements_options.classList.toggle('show-options')
      departements_btn.classList.toggle('before-btn-departements')
      departements_btn.classList.toggle('after-btn-departements')
    })

    //**  *****************  Multiple checked (via régions ) *********  */
    checkbox_Region.forEach(province => {
      province.addEventListener('change', function(province) {
        if (this.checked) {
          // console.log(this.value)
          checkbox_dpt.forEach(dpt => {
            if (dpt.dataset.region === this.value) {
              // console.log(dpt.id)
              return (dpt.checked = true)
            }
          })
          if (!departements_options.classList.contains('show-options')) {
            departements_options.classList.add('show-options')
          }
        } else {
          checkbox_dpt.forEach(dpt => {
            if (dpt.dataset.region === this.value) {
              return (dpt.checked = false)
            }
          })
        }
      })
    })

    //**  ***************** Display localisations selected  **********************************  */
    localisation_submit_btn.addEventListener('click', () => {
      let localisation_list = []
      let localisation_select_count = document.getElementById('localisation-select_count')

      checkbox_dpt.forEach(input => {
        if (input.checked) localisation_list.push(input.value)
      })

      localisation_select_count.innerHTML = localisation_list.length
      localisation_select_count.classList.remove('hide')
      form_container.classList.add('close-light-box')
      localisation_form_overlay.classList.add('close-light-box')
    })
    if (location.pathname !== '/') {
      document.addEventListener(
        'DOMContentLoaded',
        function() {
          localisation_submit_btn.click()
        },
        false
      )
    }
  }
}
