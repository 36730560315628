export default function Annonce_form() {
  let close = Array.from(document.getElementsByClassName('close'))
  let form_overlay = document.getElementById('form-overlay')
  let search_container = document.getElementsByClassName('search-container')

  let localisation_btn = document.getElementById('localisation-btn')
  let metiers_btn = document.getElementById('metiers-btn')
  let niveaux_etudes_btn = document.getElementById('niveaux-etudes-btn')
  let types_contrats_btn = document.getElementById('types-contrats-btn')

  let localisations_box = document.getElementById('localisations-box')
  let metiers_box = document.getElementById('metiers-box')
  let niveaux_etudes_box = document.getElementById('niveaux-etudes-box')
  let contrats_box = document.getElementById('contrats-box')

  if (search_container.length !== 0) {
    localisation_btn.addEventListener('click', () => {
      localisations_box.classList.toggle('close-light-box')
      form_overlay.classList.toggle('close-light-box')
    })
    metiers_btn.addEventListener('click', () => {
      metiers_box.classList.toggle('close-light-box')
      form_overlay.classList.toggle('close-light-box')
    })
    niveaux_etudes_btn.addEventListener('click', () => {
      niveaux_etudes_box.classList.toggle('close-light-box')
      form_overlay.classList.toggle('close-light-box')
    })
    types_contrats_btn.addEventListener('click', () => {
      contrats_box.classList.toggle('close-light-box')
      form_overlay.classList.toggle('close-light-box')
    })

    close.map(btn => {
      btn.addEventListener('click', () => {
        localisations_box.classList.add('close-light-box')
        metiers_box.classList.add('close-light-box')
        niveaux_etudes_box.classList.add('close-light-box')
        contrats_box.classList.add('close-light-box')
        form_overlay.classList.add('close-light-box')
      })
    })

    form_overlay.addEventListener('click', () => {
      localisations_box.classList.add('close-light-box')
      metiers_box.classList.add('close-light-box')
      niveaux_etudes_box.classList.add('close-light-box')
      contrats_box.classList.add('close-light-box')
      form_overlay.classList.add('close-light-box')
    })
  }
}
