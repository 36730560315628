export default function Autocomplete() {
  let autocomplete_container = document.getElementById('autocomplete-container')
  let autocomplete_input = document.getElementById('autocomplete-input')
  let autocomplete_choices = document.getElementById('autocomplete-choices')
  let autocomplete_tags = document.getElementById('autocomplete-tags')
  let autocomplete_checkboxes_container = document.getElementById(
    'autocomplete-checkboxes-container'
  )
  let autocomplete_submit = document.getElementById('autocomplete-submit')

  let tags_array = []


  if ((annonce_form_data !== null) && (autocomplete_container !== null)) {
    autocomplete_input.addEventListener('focus', e => {
      match_input(autocomplete_input.value, annonce_form_data, tags_array)
    })
    autocomplete_input.addEventListener('keyup', e => {
      match_input(autocomplete_input.value, annonce_form_data, tags_array)
    })

    function match_input(input_value, annonce_form_data, tags_array) {
      let match_input_result = []
      if (input_value.lenght === 0) {
        match_input_result = annonce_form_data
        return match_input_result
      }
      annonce_form_data.map((e, i) => {
        let name_lowercase = e.name.toLowerCase()
        let input_lowercase = input_value.toLowerCase()
        if (!tags_array.includes(name_lowercase)) {
          if (name_lowercase.indexOf(input_lowercase) !== -1 || input_value.lenght === '') {
            match_input_result.push(e)
          }
        }
      })
      match_input_render(match_input_result)
    }

    function match_input_render(match_input_result) {
      autocomplete_choices.innerHTML = ''
      autocomplete_choices.classList.remove('close-light-box')

      match_input_result.map(el => {
        let template = document.createElement('div')
        let text_node = document.createTextNode(el.name)
        template.setAttribute('id', el.id)
        template.setAttribute('data-region', el.parent)
        template.setAttribute('data-type', el.type)
        template.appendChild(text_node)
        autocomplete_choices.appendChild(template)

        template.addEventListener('click', ev => {
          add_tag(ev.target, autocomplete_tags)
        })
      })
    }

    function add_tag(choice_selected, tags_container) {
      let btn_remove = document.createElement('button')
      choice_selected.appendChild(btn_remove)
      choice_selected.classList.add('tag-element')
      tags_container.appendChild(choice_selected)
    }
    function remove_tag() {}
  }
}
