class SearchEngine {

    constructor() {

        // DOM Elements containers
        this.domEltAC = 'se_inputAC';
        this.domEltTags = 'se_tags';
        this.domEltChoices = 'se_choices';
        // DOM Elements specifics
        this.domEltTagsNumber = 'count-localisation-tag';
        this.domEltChoicesNumber = 'count-choices';
        this.domEltTagsInputs = 'se_tags_inputs';
        // Attributes
        this.data = null;
        this.tags = {};
        this.init();
    }

    /**
     * Init data and listeners
     */
    init() {

        if (annonce_form_data != null) {
            this.data = annonce_form_data;
            this.bindListener();
        }

        let self = this;
        let countItem = 0;
        if (session_localisations != null) {
            let countSessionLocalisations = Object.keys(session_localisations).length;
            if(countSessionLocalisations !== 0) {
                Object.keys(session_localisations).forEach(function (key) {
                    self.tags[session_localisations[key].name] = session_localisations[key];
                    countItem++;
                    if (countItem === countSessionLocalisations) {
                        self.tagsNumberRender();
                    }
                });
            }
        }
        self.tagsNumberRender();
    }

    /**
     * Bind all events
     */
    bindListener() {

        let self = this;

        $("#" + this.domEltAC).on('keyup', function () {
            let searchValue = $(this).val();
            self.matchInput(searchValue);
        });

        $("body").on('click', '.add_tag', function () {
            let addValue = $(this).data('name');
            let attributes = new Object();
            attributes.type = $(this).data('type');
            attributes.definition = $(this).data('definition');
            attributes.id = $(this).data('id');
            attributes.parent = $(this).data('parent');
            attributes.name = $(this).data('name');
            self.addTag(addValue, attributes);
        });

        $("body").on('click', '.remove_tag', function () {
            let removeValue = $(this).data('name');
            self.removeTag(removeValue);
        });
    }

    /**
     * Find cities, departements or regions with search value
     * @param searchValue
     */
    matchInput(searchValue) {

        let self = this;
        let results = [];
        this.data.map((e, i) => {
            let name_lowercase = e.name.toLowerCase();
            let searchValue_clean = searchValue.toLowerCase();
            if (e.name in self.tags) {
                // silence is golden
            } else {
                if (name_lowercase.indexOf(searchValue_clean) !== -1) {
                    results.push(e);
                }
            }
        });
        this.matchInputRender(results);
        this.choicesNumberRender(results);
    }

    /**
     * Add selected choice
     * @param addValue
     */
    addTag(addValue, attributes) {

        this.tags[addValue] = attributes;
        this.tagRender();
        $("#" + this.domEltAC).trigger('keyup');
    }

    /**
     * Remove selected choice
     * @param removeValue
     */
    removeTag(removeValue) {
        if (removeValue in this.tags) {
            delete this.tags[removeValue];
        }
        this.tagRender();
        $("#" + this.domEltAC).trigger('keyup');
    }

    /**
     * Display choices list
     * @param results
     */
    matchInputRender(results) {
        $("#" + this.domEltChoices).html('');
        results.map(el => {
            let localisation_type = 'Ville';
            if (el.definition === 'localisation') {
                localisation_type = 'Département';
                if (el.parent === 0) {
                    localisation_type = 'Région';
                }
            }
            let indicator = '<span>' + localisation_type + ' : </span>';
            $("#" + this.domEltChoices).append('<div class="add_tag" data-name="' + el.name + '" data-type="' + el.type + '" data-definition="' + el.definition + '" data-id="' + el.id + '" data-parent="' + el.parent + '">' + indicator + el.name + '</div>');
        });
    }

    /**
     * Display tags list
     */
    tagRender() {
        let self = this;
        $("#" + this.domEltTags).html('');
        Object.keys(this.tags).forEach(function (key) {
            $("#" + self.domEltTags).append('<span class="remove_tag" data-name="' + self.tags[key].name + '" data-type="' + self.tags[key].type + '" data-definition="' + self.tags[key].definition + '" data-id="' + self.tags[key].id + '" data-parent="' + self.tags[key].parent + '">' + self.tags[key].name + '</span>');
        });
        this.tagsNumberRender();
    }

    /**
     * Display choices number
     * @param results
     */
    choicesNumberRender(results) {
        let content = results.length;
        $("#" + this.domEltChoicesNumber).html(content);
    }

    /**
     * Display tags number
     */
    tagsNumberRender() {
        let content = Object.keys(this.tags).length;
        $("#" + this.domEltTagsNumber).html(content);
        if (content === 0) {
            $("#" + this.domEltTagsNumber).addClass('close-light-box');
        } else {
            $("#" + this.domEltTagsNumber).removeClass('close-light-box');
        }
        this.tagsGenerateInputs();
    }

    tagsGenerateInputs() {
        let self = this;
        let numberTags = Object.keys(this.tags).length;
        $("#" + self.domEltTagsInputs).html('');
        Object.keys(this.tags).forEach(function (key) {
            let value = '';
            if (self.tags[key].type == 'acf') {
                value = self.tags[key].type + '_' + self.tags[key].name;
            } else {
                value = self.tags[key].type + '_' + self.tags[key].id;
            }
            $("#" + self.domEltTagsInputs).append('<input checked type="checkbox" name="f_localisation[]" value="' + value + '" />');
        });
    }

}

export {SearchEngine as default}