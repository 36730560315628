export default function CheckboxesChoices() {
  let metiers_box = document.getElementById('metiers-box')
  let niveaux_etudes_box = document.getElementById('niveaux-etudes-box')
  let contrats_box = document.getElementById('contrats-box')
  let metiers_checkboxes_container = document.getElementById('metiers-checkboxes-container')
  let niveaux_etudes_checkboxes_container = document.getElementById(
    'niveaux-etudes-checkboxes-container'
  )
  let types_contrats_checkboxes_container = document.getElementById(
    'types-contrats-checkboxes-container'
  )

  let count_metiers_tag = document.getElementById('count-metiers-tag')
  let count_niveaux_etudes_tag = document.getElementById('count-niveaux-etudes-tag')
  let count_types_contrats_tag = document.getElementById('count-types-contrats-tag')

  if (metiers_box !== null) {
    let metiers_checked_array = []
    Array.from(metiers_checkboxes_container.children).map( input_div => {
      let label = input_div.firstElementChild
      let input = input_div.lastElementChild

      window.addEventListener('load', e =>{
        input.checked ? label.classList.add('form-label__selected') : label.classList.remove('form-label__selected')
        input.checked ? metiers_checked_array.push("metier") : '';
        count_metiers_tag.innerHTML = metiers_checked_array.length
        if (metiers_checked_array.length === 0) {
          count_metiers_tag.classList.add('close-light-box')
        } else {
          count_metiers_tag.classList.remove('close-light-box')
        }
      })
      label.addEventListener('click', e => {
        input.checked ? metiers_checked_array.pop() : metiers_checked_array.push("metier");
        count_metiers_tag.innerHTML = metiers_checked_array.length
        if (metiers_checked_array.length === 0) {
          count_metiers_tag.classList.add('close-light-box')
        } else {
          count_metiers_tag.classList.remove('close-light-box')
        }
        input.checked ? label.classList.remove('form-label__selected') : label.classList.add('form-label__selected')    
      })
    })
  }

  if (niveaux_etudes_box !== null) {
    let etudes_checked_array = []
    Array.from(niveaux_etudes_checkboxes_container.children).map(input_div => {
      let label = input_div.firstElementChild
      let input = input_div.lastElementChild

      window.addEventListener('load', e =>{
        input.checked ? label.classList.add('form-label__selected') : label.classList.remove('form-label__selected')
        input.checked ? etudes_checked_array.push("etude") : '';
        count_niveaux_etudes_tag.innerHTML = etudes_checked_array.length
        if (etudes_checked_array.length === 0) {
          count_niveaux_etudes_tag.classList.add('close-light-box')
        } else {
          count_niveaux_etudes_tag.classList.remove('close-light-box')
        }
      })
      label.addEventListener('click', e => {
        input.checked ? etudes_checked_array.pop() : etudes_checked_array.push("etude");
        count_niveaux_etudes_tag.innerHTML = etudes_checked_array.length
        if (etudes_checked_array.length === 0) {
          count_niveaux_etudes_tag.classList.add('close-light-box')
        } else {
          count_niveaux_etudes_tag.classList.remove('close-light-box')
        }
        input.checked ? label.classList.remove('form-label__selected') : label.classList.add('form-label__selected')    
      })
    })
  }

  if (contrats_box !== null) {
    let contrats_checked_array = []
    Array.from(types_contrats_checkboxes_container.children).map(input_div => {
      let label = input_div.firstElementChild
      let input = input_div.lastElementChild

      window.addEventListener('load', e =>{
        input.checked ? label.classList.add('form-label__selected') : label.classList.remove('form-label__selected')
        input.checked ? contrats_checked_array.push("contrat") : '';
        count_types_contrats_tag.innerHTML = contrats_checked_array.length
        if (contrats_checked_array.length === 0) {
          count_types_contrats_tag.classList.add('close-light-box')
        } else {
          count_types_contrats_tag.classList.remove('close-light-box')
        }
      })
      label.addEventListener('click', e => {
        input.checked ? contrats_checked_array.pop() : contrats_checked_array.push("etude");
        count_types_contrats_tag.innerHTML = contrats_checked_array.length
        if (contrats_checked_array.length === 0) {
          count_types_contrats_tag.classList.add('close-light-box')
        } else {
          count_types_contrats_tag.classList.remove('close-light-box')
        }
        input.checked ? label.classList.remove('form-label__selected') : label.classList.add('form-label__selected')    
      })
    })
  }
}
