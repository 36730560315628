import $ from 'jquery'

import '../webpack/node_modules/@babel/polyfill/lib'
// import "../webpack/node_modules/regenerator-runtime/runtime";
// import Smoothscroll from './smoothscroll.js'
// import Menu from './menu_burger.js'
import Select from './select.js'
import LightBox from './light_box.js'
import Annonce from './annonce.js'
import Annonce_form from './annonce_form.js'
import Candidature from './candidature.js'
import Share from './share_btn.js'
import SelectChoices from './select_choices'
import CheckboxesChoices from './checkboxes_choices'
import 'slick-carousel'
import '../webpack/node_modules/mdn-polyfills/Array.prototype.includes'
import '../webpack/node_modules/mdn-polyfills/Array.prototype.find'
import '../webpack/node_modules/mdn-polyfills/Object.assign'
import Smoothscroll from './smoothscroll'
import Autocomplete from './autocomplete'
import SearchEngine from './search-engine.js'
// import KeyListeners from './keylisteners.js'

$(document).ready(function() {
  new SearchEngine()
});

(function() {
  
  let nav_overlay = document.querySelector('.nav-overlay')
  let nav_custom = document.querySelector('.nav-custom')

  var hamburger = {
    navToggle: document.querySelector('.nav-toggle'),
    nav: document.querySelector('.nav-custom'),

    doToggle: function(e) {
      // e.preventDefault();
      this.navToggle.classList.toggle('expanded')
      this.nav.classList.toggle('expanded')
    }
  }

  hamburger.navToggle.addEventListener('click', function(e) {
    hamburger.doToggle(e)
    nav_overlay.classList.toggle('close-light-box')
  })
  hamburger.nav.addEventListener('click', function(e) {
    hamburger.doToggle(e)
  })
  nav_overlay.addEventListener('click', function(e) {
    if (hamburger.nav.classList.contains('expanded')) {
      hamburger.doToggle(e)
      nav_overlay.classList.toggle('close-light-box')
    }
  })
  hamburger.nav.addEventListener('click', function(e) {
    console.log('click custom');
      nav_overlay.classList.toggle('close-light-box')
  })
})()

// Smoothscroll()
// let header_arrow_btn = document.querySelector(".header-arrow-btn")

// ******** Polyfill pour IE permettant d'utiliser la méthode Array.from **************************** //
/**
 * Array.from() polyfill
 */
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from
// Production steps of ECMA-262, Edition 6, 22.1.2.1
if (!Array.from) {
  Array.from = (function() {
    var toStr = Object.prototype.toString
    var isCallable = function(fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]'
    }
    var toInteger = function(value) {
      var number = Number(value)
      if (isNaN(number)) {
        return 0
      }
      if (number === 0 || !isFinite(number)) {
        return number
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number))
    }
    var maxSafeInteger = Math.pow(2, 53) - 1
    var toLength = function(value) {
      var len = toInteger(value)
      return Math.min(Math.max(len, 0), maxSafeInteger)
    }

    // The length property of the from method is 1.
    return function from(arrayLike /*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      var C = this

      // 2. Let items be ToObject(arrayLike).
      var items = Object(arrayLike)

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError('Array.from requires an array-like object - not null or undefined')
      }

      // 4. If mapfn is undefined, then let mapping be false.
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined
      var T
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function')
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2]
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      var len = toLength(items.length)

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method
      // of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      var A = isCallable(C) ? Object(new C(len)) : new Array(len)

      // 16. Let k be 0.
      var k = 0
      // 17. Repeat, while k < len… (also steps a - h)
      var kValue
      while (k < len) {
        kValue = items[k]
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k)
        } else {
          A[k] = kValue
        }
        k += 1
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len
      // 20. Return A.
      return A
    }
  })()
}

// ******** Polyfill pour IE permettant d'utiliser la méthode foreach sur des nodelist ************** //
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}
// ************************************************************************************************** //


SelectChoices()
CheckboxesChoices()
Smoothscroll()
Select()
LightBox()
Annonce()
Candidature()
Share()
Annonce_form()
Autocomplete()
// KeyListeners()

$(document).ready(function() {

  $('.layout-galerie .slider').slick({
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
    // centerPadding: 0,
  })

  $('.caroussel-candidature').slick({
    dots: false,
    fade: true,
    arrows: false,
    autoplay: true
  })

  $('.input-file-trigger').on('click', function() {
    $(this)
      .parent()
      .find('.input-file')
      .focus()
    return false
  })

  $('.input-file').hover(
    function() {
      // console.log("HOVER");
      $(this)
        .parent()
        .find('.input-file-trigger')
        .addClass('active')
    },
    function() {
      $(this)
        .parent()
        .find('.input-file-trigger')
        .removeClass('active')
    }
  )

  $('.input-file').on('change', function() {
    $(this)
      .parent()
      .find('.input-return')
      .html($(this).val());
    $(this)
        .parent()
        .find('.msg-error')
        .css('display', 'none');
  })

  $('.container-form form input').on('keyup', function() {
    if ($(this).val() == '') {
      $(this)
        .parent()
        .find('label')
        .hide()
    } else {
      $(this)
        .parent()
        .find('label')
        .show()
    }
  })

  /*
  $('#formcandidature').on('submit', function(e) {
    e.preventDefault()

    var flag = true

    if ($('#ldm')[0].files[0]) {
      if ($('#ldm')[0].files[0].size > 4000000) {
        $('#ldmerror').html(
          '<div style="color: red; width: 100%;">le fichier est trop gros, il ne doit pas dépasser 4MB</div>'
        )
      } else {
        $('#ldmerror').html('')
      }
    }
    if ($('#cv')[0].files[0]) {
      if ($('#cv')[0].files[0].size > 4000000) {
        flag = false
        $('#cverror').html(
          '<div style="color: red; width: 100%;">le fichier est trop gros, il ne doit pas dépasser 4MB</div>'
        )
      } else {
        flag = true
        $('#cverror').html('')
      }
    }
    var formdata = new FormData(this)
    // console.log(formdata);

    formdata.append('action', 'candidature')

    if (flag) {
      $.ajax({
        //url: 'https://bigard.local:4435/api/candidature',
        url: ajaxurl_annonce,
        type: 'post',
        dataType: 'json',
        contentType: false,
        processData: false,
        data: formdata
      }).done(function(data) {
        var values = JSON.parse(data)
        $('#nomerror').html('')
        $('#prenomerror').html('')
        $('#emailerror').html('')
        $('#telephoneerror').html('')
        $('#cverror').html('')
        // console.log(values);
        if (values.error) {
          for (var key in values.error) {
            $('#' + key + 'error').html(
              '<div style="color: red; width: 100%;">' + values.error[key] + '</div>'
            )
          }
        } else {
          $('#zoneform').css({
            'font-size': '16px',
            'background-color': '#41b883',
            color: '#FFF',
            'text-align': 'center',
            'line-height': '26px',
            'font-weight': '300',
            padding: '1rem',
            margin: '5rem 18vw'
          })
          $('#zoneform').html('Votre candidature a bien été enregistrée.')
        }
      })
    }
  }) */
})
