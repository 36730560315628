//import detectIt from 'detect-it';

export default function Candidature() {
    let input_candidature = document.getElementsByClassName("input-candidature");
    // let label_candidature = document.getElementsByClassName("label-candidature")

    // if passive events are supported by the browser
    /* if (detectIt.passiveEvents === true) {
       Array.from(input_candidature).forEach(el => {
         el.addEventListener('input', function(){
           if((el.value !== '') && (el.parentNode.firstElementChild.classList.contains('close-light-box'))){
             el.parentNode.firstElementChild.classList.remove('close-light-box')
           }
         })
       }, { capture: false, passive: true });
     } else { */
    Array.from(input_candidature).forEach(el => {
        el.addEventListener('input', function () {
            if ((el.value !== '') && (el.parentNode.firstElementChild.classList.contains('close-light-box'))) {
                el.parentNode.firstElementChild.classList.remove('close-light-box')
            }
        });

        el.addEventListener('keyup', function () {
            let parentNode = el.parentNode;
            // Check if an error occured
            if (el.hasAttribute("required") && parentNode.classList.contains("error") && parentNode.querySelector('.msg-error') != null && el.value === '') {
                parentNode.querySelector('.msg-error').style.display = 'block';
            } else {
                parentNode.querySelector('.msg-error').style.display = 'none';
            }
        })


    }, false);


    let selectInputs = document.querySelectorAll('.select-items div');
    for (let i = 0; i < selectInputs.length; i++) {
        // Get the parent div custom-select
        let parentNode = selectInputs[i].parentNode.parentNode;
        selectInputs[i].addEventListener('click', function(el) {
            if( parentNode.querySelector('.msg-error') != null) {
                parentNode.querySelector('.msg-error').style.display = 'none';
            }
        });
    }



    /* }*/
}
