export default function Smoothscroll() {
let scroll_link = document.getElementsByClassName('scroll-link')

  $(document).ready(function() {
    // Add smooth scrolling to all links
    // $('a').on('click', function(event) {
    if(scroll_link !== null){
      $('.scroll-link').on('click', function(event) {
        var location = window.location.href.split('?')[0]
        var link = this.href.split('?')[0]
        
        if (link === location) {
          event.preventDefault()
          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== '') {
            // Prevent default anchor click behavior
  
            // Store hash
            var hash = this.hash
            console.log(link)
            console.log(location)
  
            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
  
            $('html, body').animate(
              {
                scrollTop: $(hash).offset().top
              },
              800,
              function() {
                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash
              }
            )
          } // End if
        }
        })
    }
  })
}
